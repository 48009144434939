import RequestMixin from "./requestMixin";
import { mapMutations } from "vuex";
import moment from "moment";

export default {
  data: () => ({
    userEmail: null,
  }),
  mixins: [
    RequestMixin,
  ],

  methods: {
    ...mapMutations(["loading", "showmsg"]),

    async createCase() {
      if (!this.$refs.NewTicketForm.validate()) return;

      let configuredCaseData = this.mountCaseData();
      let payload = await this.mountPayload(configuredCaseData);

      await this.createCaseWithAttachment(payload);
    },

    async createCaseEvent() {
      let payload = {
        entityId: null,
        caseComment: {
          name: null,
          res_id: null,
          body: null,
          model: null,
          message_type: null,
          email_from: this.userEmail,
        },
        files: []
      }

      let emptyFile = false;
      let emptyFileNames = "";

      const files = this.currentCase.files.map((file) => {
        let fileName = file.name;

        if (!(file.body.split(",")[1])) {
          emptyFile = true;
          emptyFileNames += file.name + "<br/>"
        }

        return {
          name: fileName,
          datas: file.body.split(",")[1],
          description: "CLIENT",
        };
      });

      if( emptyFile ) {
        this.showmsg({ text: 'Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>' + emptyFileNames + '<b>', type: "error" });
        throw "Empty File"
      }

      payload.entityId = this.currentCase.id;
      payload.caseComment.body = this.currentCase.message;
      payload.caseComment.message_type = "comment";
      payload.caseComment.model = "helpdesk.ticket";
      payload.caseComment.name = moment(new Date()).format("DD/MM/YYYY - HH:mm");
      payload.caseComment.res_id = this.currentCase.id;
      payload.files = files;

      this.loading(true);

      await this._caseService.CreateCaseEvent(payload)
        .then(async () => {
          await this.loadServiceComents(this.currentCase)
        })
        .catch((err) => this.showmsg(err))
        .finally(() => {
          this.resetCurrentCaseForm();
          this.loading(false)
        });
    },

    async createCaseWithAttachment(payload) {
      this.loading(true);

      await this._caseService
        .CreateCaseWithAttachment(payload)
        .then(async (response) => {
          this.resetTicketFlow();
          this.tickets = await this.getImplantationCase(this.defaultFieldsTicket);
        })
        .catch((err) => this.showmsg(err))
        .finally(() => this.loading(false));
    },

    mountCaseData() {
      return {
        name: this.ticket.solicitation.name,
        xipp_contract_id: {
          id: this.ticket.contract.id,
        },
        partner_id: {
          id: this.ticket.contract.contract_owner_id.id,
        },
        visible_to_the_customer: true,
        description: this.ticket.description,
        ticket_type_id: this.ticket.solicitation.code,
        xipp_email_web: this.userEmail,
        xipp_origin_id: "rh_protegido",
        xipp_attendee_name: "",
        xipp_parent_id: this.ticket.solicitation.code === 'implementation_documents_and_contracts'
          ? this.ticket.contract.contractual_agreement
          : null,
        xipp_viewer_permission_type_id: { "code": "m" },
        email_from: this.userEmail,
      }
    },

    async mountPayload(configuredCaseData) {
      let emptyFile = false;
      let emptyFileNames = "";

      if (!this.$refs.customFileUpload.filesSizeLimitValidation(this.ticket.files)) return;

      const files = this.ticket.files.map((file) => {
        let fileName = file.name;

        if (!(file.body.split(",")[1])) {
          emptyFile = true;
          emptyFileNames += file.name + "<br/>"
        }

        let fileObject = {
          name: fileName,
          datas: file.body.split(",")[1],
          description: "CLIENT",
        };

        return fileObject;
      });

      if( emptyFile ) {
        this.showmsg({ text: 'Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>' + emptyFileNames + '<b>', type: "error" });
        throw "Empty File"
      }

      return {
        caseObject: configuredCaseData,
        files: files,
        usersPermissionView: [],
      }
    },

    resetTicketFlow() {
      this.showTicketForm = false;

      this.resetTicketForm();
    },

    resetCurrentCaseForm() {
      this.currentCase.files = [];
      this.currentCase.message = "";
    },

    resetTicketForm() {
      this.ticket = {
        solicittation: null,
        contract: {},
        description: null,
        files: [],
      };
    },
  },

  computed: {
    defaultFieldsTicket() {
      return [
        'id',
        'name',
        'xipp_external_protocol',
        'create_date',
        'partner_id',
        'partner_id.name',
        'partner_name',
        'stage_id',
        'xipp_helpdesk_display_stage',
        'description',
        'xipp_attendee_name',
        'ticket_type_id',
        'xipp_contract_id',
        'xipp_contract_id.carrier_id',
        'xipp_contract_id.carrier_id.xipp_commercial_name',
        'xipp_contract_id.policy',
        'xipp_benefit_id',
        'state',
        '__last_update',
        'xipp_name_web',
      ];
    },

    defaultFieldsContract() {
      return ['id','state','xipp_contract_id','xipp_contract_id.id','xipp_contract_id.benefit_id',
        'xipp_contract_id.carrier_id','xipp_contract_id.carrier_id.xipp_commercial_name','xipp_contract_id.contract_owner_id',
        'xipp_contract_id.contract_owner_id.name','xipp_contract_id.contract_owner_id.legal_name','xipp_contract_id.contract_owner_id.xipp_commercial_name',
        'xipp_contract_id.cnpj','xipp_contract_id.state','xipp_contract_id.policy','xipp_contract_id.start_date',
        'xipp_contract_id.cut_off_date','xipp_contract_id.carrier_data_cancelation','xipp_contract_id.moderator_variable',
        'xipp_contract_id.due_date_invoice','xipp_contract_id.subcontract','xipp_contract_id.state'];
    },
  },

  mounted() {
    this.userEmail = this.$util.getSession() && this.$util.getSession().email
      ? this.$util.getSession().email
      : null;
  },
}