<template>
  <v-container
    fluid
    class="px-0">
    <v-data-table
      v-model="localModel"
      v-bind="config"
      v-on="config.on"
      fixed-header
      hide-default-header
      min-height="300"
      :page.sync="page"
      :items="items"
      :items-per-page="itemsPerPage"
      :loading="loading"
      :hide-default-footer="
        items.length === 0
        || config.hideDefaultFooter
        && config.hideDefaultFooter === true"
      :loading-text="loadingMessage"
      :footer-props="{
        disablePagination: loading,
      }"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc">
      <template v-slot:header="{ props }">
        <v-container
          fluid
          class="pa-0">
          <v-row
            style="
              min-height: 60px;
              background-color: #5555A5 !important;
              margin: 0 1px;"
            class="d-flex align-center rounded-lg py-0">
            <v-col
              v-for="(header, index) in props.headers"
              :key="index"
              :class="`d-flex justify-${header.align}`"
              v-bind="header.attrs"
              @click="header.sortable === true ? toggleSort(header.value) : false">
              <label
                class="text-subtitle-1 white--text font-weight-bold d-flex align-center"
                style="cursor: pointer;">
                {{ header.text }}
                <v-icon
                  v-if="sortBy === header.value && header.sortable === true"
                  size="16"
                  class="ml-2"
                  :color="arrowColor">
                  {{ sortDesc ? 'fas fa-arrow-down' : 'fas fa-arrow-up' }}
                </v-icon>
              </label>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item="props">
        <v-container
          fluid
          class="py-1 px-0">
          <v-row
            class="rounded-lg elevation-2 d-flex align-center"
            style="margin: 0 1px; min-height: 60px;">
            <v-col
              v-for="(header, i) in props.headers"
              :key="i"
              :class="`d-flex justify-${header.align}`"
              v-bind="header.attrs">
              <slot
                :name="`item.${header.value}`"
                v-bind="props"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "SendDefaultTable",

  data: () => ({
    page: 1,
    localModel: [],
    sortBy: null,
    sortDesc: false,
  }),

  props: {
    config: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Carregando...",
    },
    arrowColor: {
      type: String,
      default: "white"
    },
    itemsPerPage: {
      type: Number,
      default: -1
    }
  },

  watch: {
    localModel: {
      immediate: true,
      handler(newVal) {
        this.$emit('updateModel', [...newVal]);
      },
    },
  },

  methods: {
    toggleSort(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    },
  },

  mounted() {
    const firstColumnSortable = this.config.headers.find(header => header.sortable === true);
    this.sortBy = firstColumnSortable.value;
  },
};
</script>
