<template>
  <Modal
    ref="modal"
    :title="'Críticas'"
    :useBackBtn="true"
    :useCloseBtn="false"
    @close="close">
    <template
      slot="body">
      <div
        class="px-6 py-4">
        <div
          class="pb-3">
          <UserMessage
            :message="'O documento enviado foi criticado devido a inconsistências.\
              Por favor, revise as informações, faça os ajustes necessários e\
              carregue o arquivo novamente.'"
          />
        </div>

        <p
          class="font-weight-bold">
          Histórico de críticas
        </p>

        <div
          class="overflow-y-auto"
          style="max-height: 700px;">
          <SendDocumentsTable
            :items="items"
            :config="modalConfig">
            <template v-slot:[`item.name`]="{ item }">
              <div
                class="d-flex align-center flex-row">
                <v-icon
                  :key="item.id"
                  color="secondary"
                  size="17"
                  class="mr-2">
                  fas fa-paperclip
                </v-icon>

                <span v-html="item.name" />
              </div>
            </template>

            <template v-slot:[`item.sendDate`]="{ item }">
              <span>
                {{ item.sendDate
                  ? moment(item.sendDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : '-'
                }}
              </span>
            </template>

            <template v-slot:[`item.analysis`]="{ item }">
              <span v-html="item.analysis ? item.analysis : '-'" />
            </template>

            <template v-slot:[`item.returnedDate`]="{ item }">
              {{ item.lastRiskDate
                ? moment(item.lastRiskDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                : '-'
              }}
            </template>
          </SendDocumentsTable>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import SendDocumentsTable from './sendDocumentsTable.vue';
import Modal from '../../components/structure/modal.vue';
import UserMessage from '../../components/structure/userMessage.vue';
import moment from 'moment';
export default {
  name: "CriticsModal",

  components: {
    SendDocumentsTable,
    UserMessage,
    Modal,
  },

  data: () => ({
    moment,
    items: [],
  }),

  methods: {
    open(items) {
      this.items = items;
      this.$refs.modal.open();
    },
    close() {
      this.items = [];
    },
  },

  computed: {
    modalConfig() {
      return {
        noDataText: "Nenhuma crítica encontrada",
        headers: [
          {
            text: 'Arquivo',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Enviado',
            value: 'sendDate',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Motivo',
            value: 'analysis',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Devolvido',
            value: 'returnedDate',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 3,
              xl: 3,
            }
          },
        ]
      }
    },
  }
}
</script>