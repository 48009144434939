import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.attrs.vMask)?_c(VTextField,_vm._b({class:{
      'textfield-styles': !!_vm.attrs.title,
      'rounded-lg v-input--is-focused v-aplication': true,
      'gray--text': _vm.disabled,
      'primary--text': !_vm.disabled,
    },attrs:{"disabled":_vm.disabled,"outlined":"","dense":"","hide-details":""},on:{"focus":_vm.applyFocus,"focusout":function($event){_vm.focus = false}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center v-aplication v-primary--text prepend-styles rounded-l-lg",style:(`border-right: 2px solid; background-color: #F6F2FC; height: ${_vm.height}`)},[_c('span',[_vm._v(" "+_vm._s(_vm.attrs.title)+" ")])])]},proxy:true}],null,false,3563481781),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},'v-text-field',_vm.attrs,false)):_c(VTextField,_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.attrs.vMask),expression:"attrs.vMask"}],class:{
      'textfield-styles': !!_vm.attrs.title,
      'rounded-lg v-input--is-focused v-aplication': true,
      'gray--text': _vm.disabled,
      'primary--text': !_vm.disabled,
    },attrs:{"disabled":_vm.disabled,"outlined":"","dense":"","hide-details":""},on:{"focus":_vm.applyFocus,"focusout":function($event){_vm.focus = false}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center v-aplication v-primary--text prepend-styles rounded-l-lg",style:(`border-right: 2px solid; background-color: #F6F2FC; height: ${_vm.height}`)},[_c('span',[_vm._v(" "+_vm._s(_vm.attrs.title)+" ")])])]},proxy:true}]),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},'v-text-field',_vm.attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }