<template>
  <v-container
    fluid
    grid-list-md
    class="fill-height"
    style="background-color: #F0F0F0; width: 100%; padding-bottom: 70px;">
    <v-card
      class="fill-height py-4 px-8 rounded-lg"
      width="100%">
      <UserMessage
        :message="`
          Nesta etapa precisamos que carregue para cada Benefício os documentos listados abaixo:<br>
          - Modelo de fatura<br>
          - Modelo de rateio<br>
          - Modelo de folha de pagamento<br>
          - Modelo operacional<br>
          - E/ou outros modelos necessários`"
      />

      <SendDocumentsTable
        :items="operationalMappings"
        :config="config">
        <template v-slot:[`item.carrierName`]="{ item }">
          <span v-html="item.carrierName"></span>
        </template>

        <template v-slot:[`item.critics`]="{ item }">
          <v-icon
            :key="item.id"
            color="#5555A5"
            size="17"
            @click="openCriticsModal(item)"
            v-if="hasRiskAttachments(item)">
            fas fa-exclamation-triangle
          </v-icon>
        </template>

        <template v-slot:[`item.operationalMappingDocumentDTOS`]="{ item }">
          <div
            class="d-flex flex-row">
            <v-menu
              bottom
              right
              open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  size="17"
                  v-on="on"
                  v-bind="attrs">
                  fas fa-paperclip
                </v-icon>
              </template>

              <v-list
                style="background-color: #DCF0EE; max-height: 200px;"
                class="overflow-y-auto py-0">
                <div
                  v-if="item.operationalMappingDocumentDTOS.length > 0">
                  <v-list-item
                    v-for="(document, i) in item.operationalMappingDocumentDTOS"
                    :key="i"
                    class="py-0">
                    <v-list-item-icon>
                      <v-icon
                        color="secondary"
                        size="17">
                        fas fa-paperclip
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-title
                      style="color: #44B7AB;">
                      {{ document.name }}
                    </v-list-item-title>
                  </v-list-item>
                </div>

                <div
                  v-else
                  class="px-2">
                  <span
                    style="color: #44B7AB;">
                    Nenhum anexo encontrado
                  </span>
                </div>
              </v-list>
            </v-menu>
          </div>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <FileDropzone
            ref="FileDropzone"
            :key="item.id"
            :disabled="attachmentsValidation(item)"
            @files="setFiles(item, $event)"
          />
        </template>
      </SendDocumentsTable>

      <div
        class="d-flex justify-end align-end">
        <v-btn
          v-if="operationalMappings.length > 0"
          width="200"
          color="secondary"
          class="rounded-lg mr-3"
          @click="cancelChecklist">
          Cancelar
        </v-btn>

        <v-btn
          v-if="operationalMappings.length > 0"
          width="200"
          color="primary"
          class="rounded-lg white--text"
          :disabled="!operationalMappings.some((mapping) => mapping.files && mapping.files.length > 0)"
          @click="updateChecklist">
          Salvar
        </v-btn>
      </div>
    </v-card>

    <CriticsModal
      ref="CriticsModal"
    />

    <v-snackbar
      v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import OperationalMappingService from '../../services/odoo/OperationalMappingService';
import ContentVersionService from '../../services/odoo/ContentVersionService';
import FileDropzone from '../../components/file-dropzone/fileDropzone.vue';
import SendDocumentsTable from '../sendDocuments/sendDocumentsTable.vue';
import AttachmentMixin from '../atendimento/functions/attachmentMixin';
import UserMessage from '../../components/structure/userMessage.vue';
import CriticsModal from '../sendDocuments/criticsModal.vue';
import { mapMutations } from 'vuex';
import { saveAs } from "file-saver";
import { lookup } from "mime-types";

export default {
  name: "OperationalMapping",

  components: {
    SendDocumentsTable,
    CriticsModal,
    FileDropzone,
    UserMessage,
  },

  data: () => ({
    operationalMappingService: null,
    contentVersionService: null,
    operationalMappings: [],
    financialGroupId: null,
    loadingMessage: '',
    snackbarText: '',
    snackbar: false,
    limitFileSize: 12,
    page: 1,
  }),

  mixins: [
    AttachmentMixin,
  ],

  methods: {
    ...mapMutations(['loading', 'showmsg']),

    cancelChecklist() {
      this.$refs.FileDropzone.cleanAllFiles();
    },

    openCriticsModal(item) {
      const riskDocuments = item.operationalMappingDocumentDTOS.filter(
        (document) => document.statusCode === 'risk');

      this.$refs.CriticsModal.open(riskDocuments);
    },

    attachmentsValidation(item) {
      return item.operationalMappingDocumentDTOS
        && item.operationalMappingDocumentDTOS.length > 0 && item.operationalMappingDocumentDTOS.some(
        (el) => !['risk'].includes(el.statusCode));
    },

    hasRiskAttachments(item) {
      return item.operationalMappingDocumentDTOS
        && item.operationalMappingDocumentDTOS.length > 0 && item.operationalMappingDocumentDTOS.some(
        (el) => ['risk'].includes(el.statusCode));
    },

    async loadOperationalMappings() {
      this.loading(true);

      await this.operationalMappingService.FindOperationalMapping(this.financialGroupId)
        .then((response) => response.data)
        .then((result) => this.operationalMappings = result)
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao buscar o mapeamento operacional!", type: "error"});
          console.error(err);
        })
        .finally(() => this.loading(false));
    },

    async downloadFile(file) {
      this.loading(true);

      await this.contentVersionService
        .getDownloadFile(file.id)
        .then((attachmentData) => {
          let file_name = file.name;
          const extension_dot = "." + file.extension

          if(!file_name.endsWith(extension_dot)) {
            file_name = file_name + extension_dot
          }

          saveAs(
            new Blob(
              [ attachmentData.data ],
              { type: lookup(file.mimeType) }
            ),
            file_name
          );
        })
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao baixar arquivo!", type: "error"});
          console.error(err);
        })
        .finally(() => (this.loading(false)));
    },

    async updateChecklist() {
      this.loading(true);

      let payload = [];

      for (const operationalMapping of this.operationalMappings) {
        if(
            operationalMapping.files
            && operationalMapping.files.length > 0
            && !this.fileSizeValidation(operationalMapping.files)
          ) {
          this.loading(false);
          return;
        }

        const attachments = (
          Array.isArray(operationalMapping.files)
            ? operationalMapping.files
            : []
          ).map(
          (attachment) => this.mountAttachment(attachment)
        );

        const files = await Promise.all(attachments);

        const operationalMappingDocumentDTOS = (
            Array.isArray(files)
              ? files
              : []
            ).map((file) => ({
          name: file.name,
          data: file.datas
        }));

        payload.push({
          taskId: operationalMapping.taskId,
          operationalMappingDocumentDTOS: operationalMappingDocumentDTOS
        })

        delete operationalMapping.files;
      }

      this.operationalMappingService.PutChecklistLine(payload)
        .then((response) => response.data)
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao atualizar o checklist!", type: "error"});
          console.error(err);
        })
        .finally(() => {
          this.loading(false);
          this.operationalMappings = [];
          this.loadOperationalMappings();
        });
    },

    setFiles(item, files) {
      item.files = files;

      this.$forceUpdate();
    },

    fileSizeValidation(files) {
      files.forEach((file) => {
        if (file.size > ( this.limitFileSize * 1000000 )) {
          this.snackbarText = `Arquivo ${file.name} esta ultrapassando tamanho máximo por arquivo de ${this.limitFileSize}MB`;
          this.snackbar = true;
          this.loading(false);
          return false;
        }
      });

      return true;
    },
  },

  computed: {
    hasFiles() {
      return this.operationalMappings.some((mapping) => mapping.files && mapping.files.length > 0)
    },

    config() {
      return {
        hideDefaultFooter: true,
        headers: [
          {
            text: 'Benefício',
            value: 'carrierName',
            align: 'start',
            sortable: true,
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Anexos',
            value: 'operationalMappingDocumentDTOS',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 3,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 4,
              xl: 3,
            }
          },
        ]
      }
    },
  },

  created() {
    this.operationalMappings = [];

    this.operationalMappingService = new OperationalMappingService();
    this.contentVersionService = new ContentVersionService();
  },

  async mounted() {
    const session = this.$util.getSession();

    if (session.financialGroup) this.financialGroupId = session.financialGroup.id;

    await this.loadOperationalMappings();
  },
}
</script>
