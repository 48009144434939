<template>
  <Modal
    ref="modal"
    :title="title"
    :useBackBtn="true"
    :useCloseBtn="false">
    <template
      slot="body">
      <div
        style="min-height: 700px;">
        <div
          style="background-color: #F6F2FC !important;">
          <v-container>
            <v-row
              style="
                min-height: 60px;
                margin: 0 1px;">
              <v-col
                class="d-flex align-center justify-start pl-0">
                <label
                  class="text-subtitle-1 black--text">
                  Nome:
                  <span
                    class="font-weight-bold">
                    {{documentName}}
                  </span>
                </label>
              </v-col>
              <v-col
                class="d-flex align-center justify-center"
                cols="1"
                v-if="documentName">
                <label
                  class="text-subtitle-1 black--text">
                  Anexo:
                </label>

                <v-menu
                  bottom
                  right
                  open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondary"
                      size="20"
                      class="ml-2"
                      v-on="on"
                      v-bind="attrs">
                      fas fa-paperclip
                    </v-icon>
                  </template>

                  <v-list
                    dense
                    style="background-color: #DCF0EE;">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon
                          color="secondary"
                          size="17">
                          fas fa-paperclip
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        style="color: #44B7AB;">
                        {{ documentName }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col
                class="d-flex align-center justify-end pr-0">
                <!-- <label
                  class="text-subtitle-1 black--text">
                  Enviado: 12/01/2024
                </label> -->
              </v-col>
            </v-row>
          </v-container>
        </div>

        <div
          style="overflow: auto;"
          class="pb-0">
          <v-container>
            <v-row>
              <v-col
                cols="4"
                v-if="isCombo">
                <label
                  style="font-size: 14px; color: #808080;">
                  Tipo de documento *
                </label>

                <v-autocomplete
                  outlined
                />
              </v-col>

              <v-col
                cols="12">
                <label
                  style="font-size: 16px;"
                  class="mr-2 font-weight-bold">
                  Histórico
                </label>

                <div
                  style="overflow-y: auto; max-height: 400px; min-height: 100px;">
                  <div
                    v-for="item in comments"
                    :key="item.id">
                    <v-sheet
                      color="#F0F0F0"
                      class="pa-2 elevation-2 rounded-lg mb-2"
                      min-height="140">
                      <v-container>
                        <v-row
                          no-gutters>
                          <v-col
                            style="max-width: 50px;">
                            <v-sheet
                              height="40"
                              width="40"
                              color="secondary"
                              class="rounded-circle d-flex justify-center align-center elevation-2">
                              <span
                                class="text-h6 font-weight-bold">
                                {{ getInitials(item.userName) }}
                              </span>
                            </v-sheet>
                          </v-col>
                          <v-col
                            cols="10"
                            class="d-flex flex-column justify-start">
                            <label
                              class="font-weight-bold my-2"
                              style="font-family: 16px;">
                              {{item.userName}}
                            </label>

                            <div v-html="formatComment(item.comment)"></div>

                            <p
                              style="color: #808080;">
                              {{moment(item.date.replace(" ", "T") + '.000Z').format("DD/MM/YYYY HH:mm")}}
                            </p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-sheet>
                  </div>
                </div>

                <v-divider />
              </v-col>

              <v-col
                cols="12"
                v-if="!isCombo">
                <label
                  style="font-size: 14px; color: #808080;">
                  Comentário*
                </label>

                <v-textarea
                  outlined
                  v-model="comment"
                  hide-details
                  width="100%"
                />
              </v-col>

              <v-col
                cols="12"
                class="my-3 pr-6">
                <v-row
                  class="d-flex justify-end">
                  <v-btn
                    large
                    color="secondary"
                    class="black--text rounded-lg"
                    width="200"
                    @click="addComment"
                    :disabled="!comment">
                    Salvar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/structure/modal.vue';
import DocumentService from '@/services/odoo/documentService.js';
import { mapMutations } from 'vuex';
import moment from 'moment';
export default {
  name: "CommentModal",

  components: {
    Modal,
  },

  props: {
    title: {
      type: String,
      default: "Novo Documento"
    },
    isCombo: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    moment,
    comments: [],
    comment: null,
    item: null,
    documentService: null,
    documentName: null,
  }),

  methods: {
    ...mapMutations(["loading"]),

    async open(item) {
      this.documentName = null;
      this.item = item;
      if (this.item.document) {
        this.documentName = this.item.document.name
      }
      await this.getComments()
      this.$refs.modal.open();
      this.loading(false);
    },

    async addComment() {
      this.loading(true);
      await this.documentService
        .AddComment({
          comment: this.comment,
          userName: this.userName,
          parentEntityId: this.item.id
        })
        .then(async (response) => {
          this.comment = null;
          await this.getComments()
        });
    },

    async getComments() {
      this.loading(true);
      await this.documentService.GetCommentsByDocumentId(this.item.id)
        .then((response) => response.data)
        .then((result) => this.comments = result)
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao buscar os comentários!"});
          console.error(err);
        })
        .finally(() => this.loading(false));
    },

    formatComment(comment) {
      return comment.replace(/\n/g, '<br>');
    },

    getInitials(fullName) {
      if (!fullName) return '';

      const names = fullName.trim().split(/\s+/);
      const firstNameInitial = names[0] ? names[0][0].toUpperCase() : '' ;
      const lastNameInitial = names[1] ? names[1][0].toUpperCase() : '';

      return firstNameInitial + lastNameInitial;
    },
  },

  created() {
    this.documentService = new DocumentService();
  },

  mounted() {
    this.userName = this.$util.getSession()
      && this.$util.getSession().name
        ? this.$util.getSession().name
        : null;
  },
}
</script>