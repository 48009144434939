import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCarousel,{attrs:{"height":"100%","show-arrows":_vm.current !== 0,"hide-delimiters":true},scopedSlots:_vm._u([{key:"next",fn:function(){return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.current === _vm.tour.length
        ? _vm.finishTour()
        : _vm.goToNext()}}},[_c(VIcon,{attrs:{"size":"36"}},[_vm._v(" mdi mdi-chevron-right ")])],1)]},proxy:true}]),model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}},[_c(VCarouselItem,[_c('div',{staticClass:"fill-height",staticStyle:{"background-color":"#5555A5"}},[_c(VContainer,{staticClass:"fill-height"},[_c(VRow,{staticClass:"fill-height"},[_c(VCol,{staticClass:"fill-height d-flex flex-column justify-space-around align-center flex-nowrap",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[_c('img',{attrs:{"src":"/img/icons/logos/xipp-rh-protegido-logo-redondo.png","height":"52px","contain":""}}),_c('span',{staticClass:"ml-2 text-h4 font-weight-black white--text"},[_vm._v(" RhProtegido ")])]),_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('p',{staticClass:"mb-1 text-h5 font-weight-light white--text text-center"},[_vm._v(" Bem-vindo ao app! ")]),_c('p',{staticClass:"mb-1 text-h5 font-weight-light white--text text-center"},[_vm._v(" É com grande satisfação que damos as boas vindas à "+_vm._s(_vm.financialGroup.name)+". ")]),_c('p',{staticClass:"mb-1 text-h5 font-weight-light white--text text-center"},[_vm._v(" Estamos prontos para iniciar esta jornada com vocês. ")])]),_c(VImg,{attrs:{"src":"/img/assets/ship.svg","max-height":"250px","width":"100%","contain":""}}),_c('p',{staticClass:"text-h6 font-weight-bold white--text"},[_vm._v(" Vamor começar? ")]),_c(VBtn,{staticClass:"black--text rounded-lg",attrs:{"color":"secondary","width":"400","height":"44","disabled":!_vm.loaded},on:{"click":_vm.goToNext}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Iniciar tour virtual ")])])],1)],1)],1)],1)]),_vm._l((_vm.tour),function(item,index){return _c(VCarouselItem,{key:index+1,staticStyle:{"background-color":"#6E6E8E"}},[_c(VImg,{attrs:{"src":item.datas}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }