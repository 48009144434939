<template>
  <Modal
    ref="modal"
    :title="'Novo Documento'"
    :useBackBtn="true"
    :useCloseBtn="false">
    <template
      slot="body">
      <div
        style="min-height: 100px; max-height: 500px; overflow: auto;"
        class="pb-0">
        <v-form
          ref="NewDocumentForm">
          <v-container
            style="height: 400px">
            <v-row
              class="fill-height">
              <v-col
                cols="12">
                <v-container
                  fluid>
                  <v-row
                    class="py-4">
                    <label
                      style="font-size: 14px; color: #808080;"
                      class="mr-2">
                      Upload de arquivos
                    </label>

                    <v-menu
                      open-on-hover>
                      <template v-slot:activator="{ on, attrs }">
                        <v-sheet
                          v-bind="attrs"
                          v-on="on"
                          height="20"
                          width="20"
                          class="rounded-circle d-flex justify-center align-center"
                          outlined
                          style="border: 2px solid #5DAAF5;">
                          <v-icon
                            color="#5DAAF5"
                            size="10">
                            fas fa-info
                          </v-icon>
                        </v-sheet>
                      </template>

                      <v-card
                        height="auto"
                        width="355"
                        color="#E0EFFE"
                        class="pa-2 rounded-lg">
                        <p
                          class="mb-0 pb-0"
                          style="color: #5DAAF5;">
                          Máximo 10 arquivos.
                        </p>

                        <p
                          style="color: #5DAAF5;">
                          Tamanho máquimo por arquivo 12MB.
                        </p>
                      </v-card>
                    </v-menu>
                  </v-row>
                </v-container>

                <FileDropzone
                  ref="FileDropzone"
                  :isMultiple="true"
                  :height="'100px'"
                  :justify="'center'"
                  @files="setFiles($event)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <v-divider />
    </template>
    <template slot="action">
      <div
          class="px-3 pt-2 pb-4" style="width: 100%">
        <v-container
            fluid>
          <v-row justify="end">
            <v-btn
                large
                color="secondary"
                class="black--text mr-6 rounded-lg text-uppercase"
                width="200"
                @click="close">
              Cancelar
            </v-btn>

            <v-btn
                large
                color="primary"
                class="white--text rounded-lg text-uppercase"
                width="200"
                :disabled="!documentType || documentType.files && documentType.files.length === 0"
                @click="$emit('updateNewDocument', documentType)">
              Salvar
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </template>
  </Modal>
</template>

<script>
/* eslint-disable */
import FileDropzone from '../../components/file-dropzone/fileDropzone.vue';
import TextField from '../../components/structure/textField.vue';
import Modal from '../../components/structure/modal.vue';
import { formrules } from '../../common/formrules';
export default {
  name: "NewDocumentModal",

  components: {
    FileDropzone,
    TextField,
    Modal,
  },

  data: () => ({
    formrules,
    documentType: {
      files: []
    },
  }),

  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    open() {
      this.$refs.modal.open();
    },

    close() {
      this.documentType = {
        files: []
      };

      if (this.$refs && this.$refs.FileDropzone) {
        this.$refs.FileDropzone.cleanAllFiles();
      }

      this.$forceUpdate();

      this.$refs.modal.close();
    },

    setFiles(files) {
      this.documentType.files = files;

      this.$forceUpdate();
    },

    validate() {
      return this.$refs.NewDocumentForm.validate();
    },
  }
}
</script>