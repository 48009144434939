<template>
  <div>
    <SendDocumentsTable
      :model="model"
      :items="contractChecklist"
      :config="config"
      :itemsPerPage="5">
      <template v-slot:[`item.carrierName`]="{ item }">
        <span v-html="item.carrierName" />
      </template>

      <template v-slot:[`item.checklistDocumentDTOS`]="{ item }">
        {{ item.checklistDocumentDTOS ? item.checklistDocumentDTOS.length : 0 }} anexos
      </template>

      <template v-slot:[`item.fill`]="{ item }">
        <div
          style="width: 100%;">
          <ProgressLoader
            :loaded="setLoader(item.checklistDocumentDTOS)"
          />
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <StatusBadge
          :label="setTemplateStatus(item.checklistDocumentDTOS).label"
          :color="setTemplateStatus(item.checklistDocumentDTOS).color"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <ActionBtn
          @click="openModal(item)"
        />
      </template>
    </SendDocumentsTable>

    <Modal
      ref="ContractsAndTermsModal"
      :title="'Contratos e Termos'"
      @close="resetAllFields">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: calc(-270px + 100vh); overflow: auto;"
          class="pb-0">
          <v-form
            ref="LayoutForm">
            <ModalContentTable
              :items="documents"
              :config="modalConfig">
              <template v-slot:[`item.name`]="{ item }">
                <div
                  class="d-flex align-center flex-row">
                  <v-sheet
                    class="rounded-circle d-flex justify-center align-center mr-1"
                    min-height="22"
                    min-width="22"
                    style="border: 2px solid #E0E0E0"
                    :color="checklistGroupStatus(setChecklistStatus(item)).color">
                    <v-icon
                      color="white"
                      size="10">
                      {{ checklistGroupStatus(setChecklistStatus(item)).icon }}
                    </v-icon>
                  </v-sheet>

                  <span v-html="item.name" />
                </div>
              </template>

              <template v-slot:[`item.attachment`]="{ item }">
                <v-menu
                  bottom
                  right
                  open-on-hover
                  v-if="hasDeploymentDocument(item) && item.deploymentDocuments[0].document">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondary"
                      size="17"
                      v-on="on"
                      v-bind="attrs">
                      fas fa-paperclip
                    </v-icon>
                  </template>

                  <v-list
                    dense
                    style="background-color: #DCF0EE;"
                    class="py-0">
                    <v-list-item
                      v-for="(document, i) in [item.deploymentDocuments[0].document]"
                      :key="i"
                      class="py-0">
                      <v-list-item-icon>
                        <v-icon
                          color="secondary"
                          size="17">
                          fas fa-paperclip
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        style="color: #44B7AB;">
                        {{ document.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.comments`]="{ item }">
                <div
                  class="d-flex justify-start align-center">
                  <v-icon
                    color="#8D8D8D"
                    size="17"
                    style="max-height: 17px;"
                    v-if="hasDeploymentDocument(item) && item.deploymentDocuments[0].statusCode === 'risk'"
                    @click="openCommentModal(item.deploymentDocuments[0])">
                    far fa-comment
                  </v-icon>
                </div>
              </template>

              <template v-slot:[`item.critics`]="{ item }">
                <div
                  class="d-flex justify-start align-center">
                  <v-img
                    style="cursor: pointer;"
                    :src="svgPath"
                    max-height="17px"
                    contain
                    color="#5555A5"
                    size="17"
                    v-if="
                      hasDeploymentDocument(item)
                      && item.deploymentDocuments[0].document
                      && item.deploymentDocuments[0].statusCode === 'risk'"
                    @click="openCriticsModal(item.deploymentDocuments[0])"
                  />
                </div>
              </template>

              <template v-slot:[`item.action`]="{ item, index }">
                <div
                  style="min-width: 280px;">
                  <FileDropzone
                    ref="FileDropzone"
                    v-if="
                      hasDeploymentDocument(item)
                      && (
                        !item.documentTypeField
                        || item.documentTypeField === 'file'
                      )"
                    :isMultiple="false"
                    @files="setFile(item.deploymentDocuments[0], $event, index)"
                    :disabled="actionFieldValidation(item.deploymentDocuments[0])"
                  />

                  <TextField
                    v-if="
                      hasDeploymentDocument(item)
                      && ['smartphone', 'email', 'text'].includes(item.documentTypeField)"
                    ref="TextField"
                    :attrs="{
                      title: setTitle(item),
                      vMask: setDocumentTypeFieldMask(item),
                      rules: setRuleTypes(item),
                    }"
                    :disabled="actionFieldValidation(item.deploymentDocuments[0])"
                    @updateValue="setDocumentValue(item.deploymentDocuments[0], $event, index)"
                  />
                </div>
              </template>
            </ModalContentTable>
          </v-form>
        </div>
        <v-divider />
      </template>
      <template slot="action">
        <div class="px-3 pt-2 pb-4" style="width: 100%">
          <v-container fluid>
            <v-row>
              <v-spacer />
              <v-btn
                  large
                  color="secondary"
                  class="black--text mr-6 rounded-lg"
                  width="200"
                  @click="closeModal">
                Cancelar
              </v-btn>

              <v-btn
                  large
                  color="primary"
                  class="white--text rounded-lg"
                  width="200"
                  :disabled="checklistPayload.length === 0"
                  @click="updateChecklist">
                Salvar
              </v-btn>
            </v-row>
          </v-container>
        </div>
      </template>
    </Modal>

    <CommentModal
      ref="CommentModal"
      :isCombo="false"
    />

    <CriticsModal
      ref="CriticsModal"
    />

    <v-snackbar
      v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ProgressLoader from '../../components/structure/progressLoader.vue';
import FileDropzone from '../../components/file-dropzone/fileDropzone.vue';
import AttachmentMixin from '../atendimento/functions/attachmentMixin';
import RequestMixin from '@/view/atendimento/functions/requestMixin';
import StatusBadge from '../../components/structure/statusBadge.vue';
import DocumentsMixins from '../../shared/mixins/documentsMixins';
import TextField from '../../components/structure/textField.vue';
import DocumentService from '@/services/odoo/documentService.js';
import SendDocumentsTable from './sendDocumentsTable.vue';
import Modal from '../../components/structure/modal.vue';
import ModalContentTable from './modalContentTable.vue';
import { formrules } from '../../common/formrules';
import { mapGetters, mapMutations } from 'vuex';
import CriticsModal from './criticsModal.vue';
import CommentModal from './commentModal.vue';
import ActionBtn from './actionBtn.vue';

export default {
  name: "ContractsAndTermsTable",

  components: {
    SendDocumentsTable,
    ModalContentTable,
    ProgressLoader,
    FileDropzone,
    CriticsModal,
    CommentModal,
    StatusBadge,
    ActionBtn,
    TextField,
    Modal,
  },

  data: () => ({
    model: [],
    documents: [],
    checklistPayload: [],
    documentService: null,
    snackbar: false,
    snackbarText: null,
    limitFileSize: 12,
    financialGroupId: null,
    formrules,
  }),

  mixins: [
    DocumentsMixins,
    AttachmentMixin,
    RequestMixin,
  ],

  methods: {
    ...mapMutations(["loading", "setChecklist"]),

    openModal(item) {
      this.documents = [...item.checklistDocumentDTOS];
      this.$refs.ContractsAndTermsModal.open();
      this.$forceUpdate();
    },

    closeModal() {
      this.documents = [];
      this.checklistPayload = [];
      this.resetAllFields();
      this.$refs.ContractsAndTermsModal.close();
    },

    openCriticsModal(item) {
      const documents = item && item.document ? [item.document] : [];
      this.$refs.CriticsModal.open(documents);
    },

    async updateChecklist() {
      if(!this.$refs.LayoutForm.validate()) {
        this.showmsg({text: 'Preencha os campos obrigatórios do formulário'});
        return;
      }

      let hasInvalidFiles = false;

      for (let checklist of this.checklistPayload) {
        if (checklist.fileContent) {
          if (this.fileSizeValidation([checklist.fileContent])) {
            hasInvalidFiles = true;
          }
          checklist.fileContent = await this.mountAttachment(checklist.fileContent);

          checklist.fileContent = checklist.fileContent.datas;
        }
      }

      if (hasInvalidFiles) return;

      this.loading(true);

      await this.documentService.UpdateChecklist(this.checklistPayload)
        .then(async () => {
          this.resetAllFields();
          await this.getChecklistDocumentsByFinancialGroupId()
          this.$refs.ContractsAndTermsModal.close();
        })
        .finally(() => {
          this.loading(false);
        });
    },

    fileSizeValidation(files) {
      let sizeValidationFailed = false;
      files.forEach((file) => {
        if (file.size > ( this.limitFileSize * 1000000 )) {
          this.snackbarText = `Arquivo ${file.name} esta ultrapassando tamanho máximo por arquivo de ${this.limitFileSize}MB`;
          this.snackbar = true;
          this.loading(false);
          sizeValidationFailed = true;
        }
      });

      return sizeValidationFailed;
    },

    setDocumentTypeFieldMask(item) {
      switch(item.documentTypeField) {
        case 'smartphone':
          return  "(##) #####-####";
        default:
          return false;
      }
    },

    setTitle(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Link";
      }
    },

    setRuleTypes(item) {
      if (!item.deploymentDocuments[0].documentValue) return [true];

      switch(item.documentTypeField) {
        case 'email':
          return  [this.formrules.email];

        case 'smartphone':
          return  [this.formrules.cellphone];

        default:
          return [true];
      }
    },

    setTextFieldMask(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Link";
      }
    },

    resetAllFields() {
      if (this.$refs.TextField) this.$refs.LayoutForm.reset();
    },

    setFile(item, file, index) {
      item.file = file;

      if (!file || (Array.isArray(file) && file.length === 0)) {
        this.removeChecklistFile(item)
      } else {
        this.updatePayload(item, index);
      }
    },

    setDocumentValue(item, documentValue, index) {
      item.documentValue = documentValue;

      this.updatePayload(item, index);
    },

    removeChecklistFile(item) {
      const checklistIndex = this.checklistPayload.findIndex((checklist) => checklist.ids.some((id) => id === item.id));

      if (checklistIndex !== -1) {
        this.checklistPayload.splice(checklistIndex, 1);
        this.$forceUpdate();
      }
    },

    updatePayload(documentDTO, index) {
      const dtoIndex = this.checklistPayload.findIndex((checklist) => checklist.ids.some((id) => id === documentDTO.id));

      const isFile = !documentDTO.documentTypeField || documentDTO.documentTypeField === 'file';

      if (dtoIndex === -1) {
        this.checklistPayload.push({
          ids: [documentDTO.id],
          fileContent: documentDTO.file ? documentDTO.file : null,
          fileName: isFile ? documentDTO.file && documentDTO.file.name ? documentDTO.file.name : null : null,
          documentValue: !isFile ? documentDTO.documentValue : null,
        })
      } else {
        if (!documentDTO.documentValue && !documentDTO.fileContent) {
          this.checklistPayload.splice(dtoIndex, 1);
          return;
        }

        this.checklistPayload[dtoIndex].deployment_carrier_document = documentDTO.id;

        if (isFile) {
          this.checklistPayload[dtoIndex].fileContent = documentDTO.file;
          this.checklistPayload[dtoIndex].documentValue = null;
        } else {
          this.checklistPayload[dtoIndex].fileContent = null;
          this.checklistPayload[dtoIndex].documentValue = documentDTO.documentValue;
        }
      }
    },
  },

  computed: {
    ...mapGetters(["getChecklist"]),

    contractChecklist() {
      const contracts = this.getChecklist.find((checklist) => checklist.checklistLevelCode === 'CONTRACT');
      return contracts.levelDTOS && contracts.levelDTOS.length > 0 ? contracts.levelDTOS : [];
    },

    svgPath() {
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/exclamation.svg";
    },

    config() {
      return {
        headers: [
          {
            text: 'Benefício',
            value: 'carrierName',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Nº de anexos',
            value: 'checklistDocumentDTOS',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: '% de Preenchimento',
            value: 'fill',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Status',
            value: 'status',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'end',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
        ]
      }
    },

    modalConfig() {
      return {
        headers: [
          {
            text: 'Nome',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 4,
              xl: 4,
            }
          },
          // {
          //   text: 'Modelo',
          //   value: 'model',
          //   align: 'start',
          //   attrs: {
          //     class: 'text--truncate',
          //     cols: 12,
          //     sm: 6,
          //     md: 6,
          //     lg: 2,
          //     xl: 2,
          //   }
          // },
          {
            text: 'Anexos',
            value: 'attachment',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Comentários',
            value: 'comments',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 1,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 3,
              xl: 2,
            }
          },
        ]
      }
    },
  },

  created() {
    this.documentService = new DocumentService();
  },

  mounted() {
    this.financialGroupId = this.$util.getSession()
      && this.$util.getSession().financialGroup
        ? this.$util.getSession().financialGroup.id
        : null;
  },
}
</script>