<template>
  <v-container
    fluid
    class="px-0 pt-0">
    <v-data-table
      v-model="localModel"
      v-bind="config"
      v-on="config.on"
      fixed-header
      hide-default-header
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :items="sortedItems"
      :loading="loading"
      :hide-default-footer="true"
      :loading-text="loadingMessage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :footer-props="{
        disablePagination: loading,
      }">
      <template v-slot:header="{ props }">
        <v-container
          fluid
          class="pa-0">
          <v-row
            :style="`
              min-height: 60px;
              background-color: ${headerColor} !important;
              margin: 0 1px;`"
            class="d-flex align-center py-0">
            <v-col
              v-for="(header, index) in props.headers"
              :key="index"
              :class="`d-flex justify-${header.align}`"
              v-bind="header.attrs">
              <label
                class="text-subtitle-1 black--text">
                {{ header.text }}
              </label>

              <v-img
                v-if="header.sortable"
                class="ml-2"
                max-width="13"
                contain
                style="cursor: pointer;"
                @click="toggleSort(header.value)"
                :src="svgSortPath(header.value)"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item="props">
        <v-container
          fluid
          class="py-1 px-0">
          <v-row
            style="margin: 0 1px;">
            <v-col
              v-for="(header, i) in props.headers"
              :key="i"
              :class="`d-flex justify-${header.align}`"
              v-bind="header.attrs">
              <slot
                :name="`item.${header.value}`"
                v-bind="props"
              />
            </v-col>
          </v-row>

          <v-divider v-if="showDivider" />
        </v-container>
      </template>
    </v-data-table>
</v-container>
</template>

<script>
export default {
  name: "ModalContentTable",

  data: () => ({
    page: 1,
    localModel: [],
    sortBy: null,
    sortDesc: false,
  }),

  props: {
    config: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Carregando..."
    },
    itemsPerPage: {
      type: Number,
      default: -1,
    },
    headerColor: {
      type: String,
      default: "#F6F2FC"
    },
    showDivider: {
      type: Boolean,
      defualt: true,
    }
  },

  methods: {
    toggleSort(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;

        if (!this.sortDesc) this.sortBy = null;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    },

    svgSortPath(headerName) {
      const sortOffSvgPath = "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/sort-off.svg";
      const sortSvgPath = "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/sort.svg";
      if (this.sortBy !== headerName) return sortOffSvgPath;
      return sortSvgPath;
    },
  },

  computed: {
    sortedItems() {
      return [...this.items].sort((a, b) => {
        if (!this.sortBy) return 0;

        let fieldA = a[this.sortBy];
        let fieldB = b[this.sortBy];

        if (typeof fieldA === "string" && typeof fieldB === "string") {
          return this.sortDesc
            ? fieldB.localeCompare(fieldA)
            : fieldA.localeCompare(fieldB);
        }
        return 0;
      });
    },
  },

  watch: {
    localModel: {
      immediate: true,
      handler(newVal) {
        this.$emit('updateModel', [...newVal]);
      },
    },
  },
}
</script>
