import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"d-flex align-center justify-center rounded-pill",style:({
    backgroundColor: _vm.color,
  }),attrs:{"height":_vm.height,"width":"100%"}},[_c('span',{style:({
      fontSize: '14px',
      color: _vm.setStatusColor({color: _vm.color})
    })},[_vm._v(" "+_vm._s(_vm.label)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }