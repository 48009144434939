<template>
  <div
    class="d-flex flex-row align-center">
    <v-progress-linear
      :value="loaded"
      :height="height"
      style="min-width: 100px;"
      background-color="#E8E8E8"
      class="progress-loader-bar rounded-xl"
      :color="loaded === 100 ? '#62C756' : '#5DAAF5'">
      <template
        v-slot:default="{ value }"
        v-if="inner && !hideLoader">
        <div
          class="d-flex justify-end">
          <strong
            :style="{
              color: value === 100 ? 'white' : '#1b1b1b'
            }">
            {{ !Number.isNaN(value) && Number.isInteger(value) ? value : value.toFixed(2) }}%
          </strong>
        </div>
      </template>
    </v-progress-linear>

    <p
      v-if="!inner && !hideLoader"
      class="ml-4"
      style="font-size: 14px;">
      {{ !Number.isNaN(loaded) && Number.isInteger(loaded) ? loaded : loaded.toFixed(2) }}%
    </p>
  </div>
</template>

<script>
export default {
  name: "ProgressLoader",

  props: {
    loaded: {
      type: Number,
      required: true,
    },

    buffer: {
      type: Number,
      required: false,
    },

    height: {
      type: Number,
      default: 30,
      required: false,
    },

    inner: {
      type: Boolean,
      default: false,
    },

    hideLoader: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style>
.progress-loader-bar > .v-progress-linear__determinate {
  border-radius: 0 20px 20px 0 !important;
  /* background: transparent !important;
  background-color: transparent !important; */
}

.progress-loader-bar > .v-progress-linear__buffer {
  background: #E8E8E8 !important;
}

.progress-loader-bar > .v-progress-linear__content {
  display: flex !important;
  justify-content: flex-end !important;
  padding-right: 10px;
}
</style>