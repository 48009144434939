const state = {
  logo: null,
  proposals: [],
  checklist: [],
};

const getters = {
  onboardingLogo: (state) => state.logo,
  getProposals: (state) => state.proposals,
  getChecklist: (state) => state.checklist,
};

const mutations = {
  setOnboardingLogo(state, data) {
    state.logo = data;
  },

  setProposals(state, data) {
    state.proposals = [...data];
  },

  setChecklist(state, data) {
    state.checklist = [...data];
  },
};

export default {
  mutations,
  getters,
  state,
};