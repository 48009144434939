import HttpService from './HttpService';

export default class AppConfigurationService {
  constructor() {
    this._httpService = new HttpService();
  }

  async GetWelcomeText() {
    return await this._httpService.get(`/api/security/app_configuration/getAppConfigurationValueByCode?key=welcome_text&code=rh_protegido&entityType=APP`);
  }
}
