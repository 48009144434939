import HttpService from './HttpService';

export default class FinancialGroupService {
  constructor() {
    this._httpService = new HttpService();
  }

  async GetLogoByFinancialGroupId(financialGroupId) {
    return await this._httpService.get(`/api/odoo/financial_group/${financialGroupId}?refresh=true`);
  }
}
