const formrules = {
  maxLength10: value => value && value.length <= 10 || "Máximo 10 caracteres.",
  required: value => !!value || "Preenchimento obrigatório.",
  valueGreaterThanZero: value => (!value || (!!value && value > 0)) || "O valor deve ser maior que zero.",
  valueGreaterEqualsThanZero: value => (!value || (!!value && value >= 0)) || "O valor deve ser maior ou igual a zero.",
  cellphone: value => (value && value.length === 15) || '*Número inválido',
  email: value => {
    if (value && value.length > 0) {
      const pattern = /^(([^<>(){}!#$%^=+*[\]\\.,;:\s@"]+(\.[^<>(){}!#$%^=+*[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'E-mail inválido';
    }

    return true;
  },
  cnpj: cnpj => {
    const cnpjNumber = cnpj && cnpj.replace(/[^\d]+/g, '');

    if (!cnpjNumber || cnpjNumber.length !== 14 || !/^\d+$/.test(cnpjNumber)) return 'CNPJ invalido';

    const invalids = [
      "00000000000000", "11111111111111", "22222222222222",
      "33333333333333", "44444444444444", "55555555555555",
      "66666666666666", "77777777777777", "88888888888888",
      "99999999999999"
    ];

    if (invalids.includes(cnpjNumber)) return 'CNPJ invalido';


    const calcDigit = (cnpj, position) => {
      let sum = 0;
      let current = position === 12
        ? [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
        : [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

      for (let i = 0; i < position; i++) {
        sum += parseInt(cnpj.charAt(i)) * current[i];
      }

      const rest = sum % 11;
      return rest < 2 ? 0 : 11 - rest;
    }

    const firstDigit = calcDigit(cnpjNumber, 12);
    if (firstDigit !== parseInt(cnpjNumber.charAt(12))) {
      return 'CNPJ invalido';
    }

    const secondDigit = calcDigit(cnpjNumber, 13);
    if (secondDigit !== parseInt(cnpjNumber.charAt(13))) {
      return 'CNPJ invalido';
    }

    return true;
  }
}

export {
  formrules
}