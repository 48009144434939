import HttpService from './HttpService'

export default class ImplantationService {
  constructor() {
    this._httpService = new HttpService();
  }

  async FindTour() {
    return await this._httpService.get("/api/odoo/knowledge/rh_protegido/implantation/tour");
  }

  async DoneTour() {
    return await this._httpService.put("/api/security/user/doneTour", {});
  }
}
