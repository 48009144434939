<template>
  <v-card
    :color="color"
    class="pa-2 rounded-lg elevation-0">
    <v-row
      class="d-flex align-start"
      no-gutters>
      <v-col
        style="max-width: 30px;"
        class="d-flex justify-center">
        <v-icon
          size="20"
          :color="textColor">
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col>
        <p
          :style="`font-size: 14px; color: ${textColor};`"
          v-html="message"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "UserMessage",

  props: {
    icon: {
      type: String,
      default: "mdi-alert-circle-outline",
      required: false,
    },
    color: {
      type: String,
      default: "#E0EFFF",
      required: false,
    },
    textColor: {
      type: String,
      default: "#5DAAF5",
      required: false,
    },
    message: {
      type: String,
      required: true,
    }
  },

  methods: {
    handleClick() {
      this.$emit("click");
    },
  },

  mounted() {
    const clickableElement = this.$el.querySelector(".clickable");
    if (clickableElement) {
      clickableElement.addEventListener("click", this.handleClick);
    }
  },

  beforeDestroy() {
    const clickableElement = this.$el.querySelector(".clickable");
    if (clickableElement) {
      clickableElement.removeEventListener("click", this.handleClick);
    }
  },
}
</script>