/* eslint-disable */
import HttpService from './HttpService'

export default class CaseService {
  constructor() {
    this._httpService = new HttpService();
  }

  async FindTicketType() {
    return await this._httpService.get(`/api/odoo/ticket-type`);
  }
}
