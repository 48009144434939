import ContentVersionService from "@/services/odoo/ContentVersionService";
import DocumentService from "../../../services/odoo/documentService";
import TicketTypeService from '@/services/odoo/TicketTypeService';
import ContractService from "@/services/odoo/ContractService";
import ComentsService from "@/services/odoo/ComentsService";
import CaseService from "@/services/odoo/CaseService";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    _contentVersionService: null,
    _documentService: null,
    _ticketTypeService: null,
    _contractService: null,
    _comentsService: null,
    _caseService: null,
    outhersDocumentTypeId: null,
  }),

  methods: {
    ...mapMutations(["showmsg", "loading", "setChecklist"]),

    async getTicketTypes() {
      return await this._ticketTypeService.FindTicketType()
        .then((response) => response.data)
        .then((result) => result)
        .catch((err) => this.showmsg(err));
    },

    async getImplantationCase(fields, status, search) {
      const params = new URLSearchParams();
      params.append('fields', fields);
      params.append('isImplantation', 'true');
      params.append('pageNumber', this.currentPage);
      params.append('pageSize', 10);
      if (status) {
        params.append('status', status);
      }
      if (search) {
        params.append('search', search);
      }

      return await this._caseService.FindByIdV2(params)
        .then((response) => {
          this.totalPages = response.data.totalPages;
          return response.data.content;
        })
        .then((result) => this.tickets = result)
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao buscar os atendimentos!", type: "error"});
          console.error(err);
        });
    },

    async getSupportTeam() {
      return await this._contractService.GetSupportTeam()
        .then((response) => response.data)
        .then((result) => result)
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao buscar o time de apoio!", type: "error"});
          console.error(err);
        });
    },

    async getContracts(fields) {
      const params = new URLSearchParams();
      params.append('fields', fields);
      await this._contractService.FindImplantationContracts(params)
        .then((res) => res.data)
        .then((result) => this.contracts = result)
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao buscar os contratos!", type: "error"});
          console.error(err);
        });
    },

    async loadServiceComents(ticket){
      this.loading(true);

      await this._comentsService.FindById(ticket.id, 'helpdesk.ticket', 'comment', 'Discussões')
        .then((response) => response.data)
        .then((result) => {
          const historic = result.records.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));
          this.serviceHistoric = historic;

          setTimeout(() => {
            const element = document.getElementById("serviceHistoricBody");
            element.scrollIntoView({ behavior: "smooth", block: "end" });
            element.scrollTop = element.scrollHeight;
          }, 500)
        })
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao buscar os comentários!", type: "error"});
          console.error(err);
        })
        .finally(() => this.loading(false));
    },

    async getChecklistTypeOuthers() {
      await this._documentService.GetChecklistTypeOuthers()
        .then((response) => response.data)
        .then((result) => this.outhersDocumentTypeId = result[0].id)
    },

    async getChecklistDocumentsByFinancialGroupId() {
      this.loading(true);

      await this._documentService.GetChecklistDocumentsByFinancialGroupId(this.financialGroupId)
        .then((response) => response.data)
        .then((result) => this.setChecklist(result))
        .catch((err) => {
          this.showmsg({ text: "Não foi possivel buscar o checklist!", type: "error" });
          console.error(err);
        })
        .finally(() => this.loading(false));
    },
  },

  created() {
    this._contentVersionService = new ContentVersionService();
    this._documentService = new DocumentService();
    this._ticketTypeService = new TicketTypeService();
    this._contractService = new ContractService();
    this._comentsService = new ComentsService();
    this._caseService = new CaseService();
  },
}
