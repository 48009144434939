<template>
  <v-container
    fluid
    fill-height>
    <iframe
      :src="`http://172.18.0.1:8081/?token=${this.user.tokenSecurityService.tinnyToken}&implantation=true`"
      height="100%"
      width="100%"
      frameborder="0"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "XippDocuments",

  computed: {
    ...mapGetters(["user"]),
  },
}
</script>