<template>
  <Modal
    ref="TimelineModal"
    :title="'Cronograma'"
    @close="close">
    <template
      slot="body">
      <div
        style="height: calc(-270px + 100vh);">
        <v-container
          fluid
          style="width: 100%; padding: 0 58px;">
          <v-row>
            <v-col
              cols="2"
              class="pt-6">
              <span
                class="font-weight-bold text-subtitle-1">
                Etapas
              </span>
            </v-col>

            <v-col
              cols="8"
              class="pb-0">
              <Stepper
                :inModal="true"
                :onlySteps="true"
                :showSelector="true"
                :steps="proposal.steps"
              />
            </v-col>

            <v-col cols="2" />
          </v-row>
        </v-container>

        <v-divider />

        <div
          style="overflow: auto; padding: 0 46px; height: calc(-470px + 100vh);"
          class="pb-0">
          <ModalContentTable
            :items="currentStep"
            :config="modalConfig"
            :showDivider="false"
            :headerColor="'transparent'">
            <template v-slot:[`item.name`]="{ item }">
              <div
                class="d-flex align-center flex-row">
                <v-sheet
                  class="rounded-circle d-flex justify-center align-center mr-1"
                  min-height="22"
                  min-width="22"
                  style="border: 2px solid #E0E0E0"
                  :color="setTimeLineStatus(item).color">
                </v-sheet>

                <span v-html="item.name" />
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <div
                class="d-flex align-center">
                <v-btn
                  :key="item.name"
                  large
                  style="max-height: 44px; max-width: 180px;"
                  color="#5555A5"
                  class="rounded-lg white--text"
                  v-if="item.name === 'Recepção e Conferencia dos documentos'"
                  @click="goToSendDocuments">
                  <span
                    class="text-capitalize text-subtitle-1">
                    Enviar Documentos
                  </span>
                </v-btn>
                <span
                  v-else>
                  -
                </span>
              </div>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <div
                class="d-flex align-center">
                <span
                  v-html="item.status"
                />
              </div>
            </template>

            <template v-slot:[`item.deliveryDate`]="{ item }">
              <div
                class="d-flex align-center">
                <v-sheet
                  color="#CDF9D9"
                  class="rounded-pill d-flex align-center justify-center"
                  height="30"
                  width="100"
                  v-if="item.deliveryDate">
                  {{ moment(item.deliveryDate, "YYYY-MM-DD").format("DD/MM/YYYY") }}
                </v-sheet>
                <span
                  v-else>
                  -
                </span>
              </div>
            </template>

            <template v-slot:[`item.finishedDate`]="{ item }">
              <div
                class="d-flex align-center">
                <v-sheet
                  color="#EAEAEA"
                  height="30"
                  width="100"
                  class="rounded-pill d-flex align-center justify-center"
                  v-if="item.finishedDate">
                  {{ moment(item.finishedDate, "YYYY-MM-DD").format("DD/MM/YYYY") }}
                </v-sheet>
                <span
                  v-else>
                  -
                </span>
              </div>
            </template>
          </ModalContentTable>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import ModalContentTable from '../sendDocuments/modalContentTable.vue';
import Stepper from '../../components/structure/stepper.vue';
import Modal from '../../components/structure/modal.vue';
import moment from 'moment';
import DocumentsMixins from '../../shared/mixins/documentsMixins';
export default {
  name: "TimelineModal",

  components: {
    ModalContentTable,
    Stepper,
    Modal,
  },

  data: () => ({
    proposal: null,
    currentStep: null,
    moment,
  }),

  mixins: [
    DocumentsMixins,
  ],

  methods: {
    open(proposal) {
      const currentStep = proposal.steps.findIndex(
        (step) => step.tasks.some((task) => task.statusCode !== 'completed'));
      this.proposal = proposal;
      this.currentStep = proposal.steps[currentStep].tasks;

      this.$nextTick(() => {
        const modal = this.$refs.TimelineModal;
        if (modal) {
          modal.open();
        } else {
          this.openModal()
        }
      });
    },

    openModal() {
      this.$refs.TimelineModal.open();
    },

    close() {
      this.proposal = null;
      this.currentStep = null;
      this.$refs.Modal.close();
    },
  },

  computed: {
    modalConfig() {
      const genClass = "d-flex align-center justify-start text--truncate";

      return {
        headers: [
          {
            text: 'Atividade',
            value: 'name',
            align: 'start',
            attrs: {
              class: genClass,
              cols: 12,
              sm: 6,
              md: 6,
              lg: 4,
              xl: 4,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'start',
            attrs: {
              class: genClass,
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Status',
            value: 'status',
            align: 'start',
            class: 'text--truncate',
            sortable: true,
            attrs: {
              class: genClass,
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Prazo',
            value: 'deliveryDate',
            align: 'start',
            attrs: {
              class: genClass,
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Finalização',
            value: 'finishedDate',
            align: 'start',
            attrs: {
              class: genClass,
              cols: 12,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
        ]
      }
    },
  }
}
</script>