/* eslint-disable */
import HttpService from '../HttpService'

export default class OperationalMappingService {
  constructor() {
    this._httpService = new HttpService("/odoo");
  }

  async FindOperationalMapping(financialGroupId) {
    return await this._httpService.get(`/contractual-agreement/operational_mapping/${financialGroupId}`);
  }

  async PutChecklistLine(payload) {
    return await this._httpService.put('/checklist_line', payload);
  }

}
