export default {
  methods: {
    calculePercentage(proposal) {
      return (this.calculateCompletedTasksInProposal(proposal) / this.calculateTotalTasksInProposal(proposal)) * 100;
    },

    calculateCompletedTasksInProposal(proposal) {
      let completedTasks = 0;

      proposal.steps.forEach(
        (step) => {
          completedTasks += step.tasks.filter((task) => !['pending', 'risk'].includes(task.statusCode)).length;
        });

      return completedTasks;
    },

    calculateTotalTasksInProposal(proposal) {
      let completedTasks = 0;

      proposal.steps.forEach(
        (step) => {
          completedTasks += step.tasks.length;
        });

      return completedTasks;
    },

    calculateCompletedTasks(tasks) {
      return tasks.filter((task) => !['pending', 'risk'].includes(task.statusCode)).length;
    },

    calculateTotalTasks(tasks) {
      return tasks.length;
    },

    calculeTasksPercentage(tasks) {
      const completeTasks = this.calculateCompletedTasks(tasks);
      const totalTasks = this.calculateTotalTasks(tasks);
      let result = 0

      if (totalTasks > 0) {
        result = completeTasks / totalTasks;
      }

      return result * 100;
    },
  }
}