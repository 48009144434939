<template>
  <div>
    <SendDocumentsTable
      :model="model"
      :items="enterpriseChecklist"
      :config="config"
      :itemsPerPage="5">
      <template v-slot:[`item.documentNumber`]="{ item }">
        <span v-html="item.documentNumber" />
      </template>
      <template v-slot:[`item.checklistDocumentDTOS`]="{ item }">
        {{ item.checklistDocumentDTOS.length }} anexos
      </template>
      <template v-slot:[`item.fill`]="{ item }">
        <div
          style="width: 100%;">
          <ProgressLoader
            :loaded="setLoader(item.checklistDocumentDTOS)"
          />
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <StatusBadge
          :label="setTemplateStatus(item.checklistDocumentDTOS).label"
          :color="setTemplateStatus(item.checklistDocumentDTOS).color"
        />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <ActionBtn
          @click="openModal(item)"
        />
      </template>
    </SendDocumentsTable>

    <v-container
      fluid>
      <v-row
        class="d-flex justify-end">
        <v-btn
            large
            outlined
            class="rounded-lg elevation-1"
            color="primary"
            width="200"
            :loading="addNewCnpjLoading"
            @click="openDocumentModal">
          Inserir CNPJ
        </v-btn>
      </v-row>
    </v-container>

    <Modal
      ref="AddDocumentModal"
      :title="'Inserir CNPJ'"
      :dialogWidth="'590px'"
      @close="closeDocumentModal">
      <template slot="body">
        <v-form ref="AddDocumentForm">
          <v-container
            class="py-10 px-15">
            <v-row
              justify="center">
              <v-col
                cols="12">
                <label
                  style="font-size: 14px; color: #808080;">
                  Contratos*
                </label>

                <v-autocomplete
                  ref="contractId"
                  outlined
                  v-model="contractId"
                  item-value="id"
                  item-text="label"
                  :items="contracts"
                  :rules="[formrules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                class="pt-0">
                <label
                  style="font-size: 14px; color: #808080;">
                  Insira o CNPJ*
                </label>

                <v-text-field
                  ref="cnpj"
                  outlined
                  v-model="cnpj"
                  placeholder="00.000.000/0000-00"
                  :rules="[formrules.required, formrules.cnpj]"
                  v-mask="'##.###.###/####-##'"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider />
      </template>
      <template slot="action">
        <div
            class="px-3 pt-2 pb-4" style="width: 100%">
          <v-container
              fluid>
            <v-row justify="end">
              <v-btn
                  large
                  color="primary"
                  class="rounded-lg"
                  width="200"
                  @click="addNewCnpj"
                  :disabled="!contractId || !cnpj || cnpj.length != 18"
                  :loading="addNewCnpjLoading">
                Salvar
              </v-btn>
            </v-row>
          </v-container>
        </div>
      </template>
    </Modal>

    <Modal
      ref="EnterpriseModal"
      :title="title"
      @close="closeModal">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: calc(-270px + 100vh); overflow: auto;"
          class="pb-0">
          <v-form
            ref="EnterpriseForm">
            <ModalContentTable
              :items="documents"
              :config="modalConfig">
              <template v-slot:[`item.name`]="{ item }">
                <div>
                  <div
                    class="d-flex align-center flex-row"
                    :style="`height: ${rowHeight}; padding: 12px 0;`">
                    <v-sheet
                      class="rounded-circle d-flex justify-center align-center mr-1"
                      min-height="22"
                      min-width="22"
                      style="border: 2px solid #E0E0E0"
                      :color="checklistGroupStatus(setChecklistStatus(item)).color">
                      <v-icon
                        color="white"
                        size="10">
                        {{ checklistGroupStatus(setChecklistStatus(item)).icon }}
                      </v-icon>
                    </v-sheet>

                    <v-tooltip
                      bottom>
                      <template
                        v-slot:activator="{ on, attrs }">
                        <div
                          v-on="on"
                          v-bind="attrs"
                          class="text-truncate"
                          style="max-width: 350px;">
                          {{ item.name }}
                        </div>
                      </template>

                      <span>
                        {{ item.name }}
                      </span>
                    </v-tooltip>
                  </div>

                  <v-container
                    fluid
                    class="pl-8">
                    <v-row
                      v-for="(checklist, checklistIndex) in item.deploymentDocuments"
                      :key="`checklist-${checklistIndex}-carrierName`">
                      <v-col
                        :style="`height: ${rowHeight};`"
                        class="d-flex align-center">
                        <div
                          class="d-flex align-center flex-row">
                          <v-sheet
                            class="rounded-circle d-flex justify-center align-center mr-1"
                            min-height="22"
                            min-width="22"
                            style="border: 2px solid #E0E0E0"
                            :color="checklistGroupStatus([checklist.statusCode]).color">
                            <v-icon
                              color="white"
                              size="10">
                              {{ checklistGroupStatus([checklist.statusCode]).icon }}
                            </v-icon>
                          </v-sheet>

                          <span v-html="checklist.carrierName" />
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </template>

              <template v-slot:[`item.attachment`]="{ item }">
                <v-container
                  fluid
                  :class="{
                    'py-3': checkShippingStatusChecklist(item),
                    'd-flex flex-column justify-end': !checkShippingStatusChecklist(item)
                  }">
                  <v-row
                    v-for="(checklist, checklistIndex) in deploymentDocuments(item)"
                    :key="`checklist-${checklistIndex}-attachment`"
                    style="max-height: 45px;">
                    <v-col
                      class="pl-0 d-flex align-center">
                      <v-menu
                        bottom
                        right
                        open-on-hover
                        v-if="checklist.document">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="secondary"
                            size="17"
                            v-on="on"
                            v-bind="attrs">
                            fas fa-paperclip
                          </v-icon>
                        </template>

                        <v-list
                          style="background-color: #DCF0EE;"
                          dense>
                          <v-list-item
                            class="py-0">
                            <v-list-item-icon>
                              <v-icon
                                color="secondary"
                                size="17">
                                fas fa-paperclip
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-title
                              style="color: #44B7AB;">
                              {{ checklist && checklist.document ? checklist.document.name : '-' }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <template v-slot:[`item.comments`]="{ item }">
                <v-container
                  fluid
                  :class="{
                    'py-3': checkShippingStatusChecklist(item),
                    'd-flex flex-column justify-end': !checkShippingStatusChecklist(item)
                  }">
                  <v-row
                    v-for="(checklist, checklistIndex) in deploymentDocuments(item)"
                    :key="`checklist-${checklistIndex}-comments`"
                    style="max-height: 45px;">
                    <v-col
                      class="pl-0">
                      <v-icon
                        color="#8D8D8D"
                        size="17"
                        v-if="checklist.statusCode === 'risk'"
                        @click="openCommentModal(checklist)">
                        far fa-comment
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <template v-slot:[`item.critics`]="{ item }">
                <v-container
                  fluid
                  :class="{
                    'py-3': checkShippingStatusChecklist(item),
                    'd-flex flex-column justify-end': !checkShippingStatusChecklist(item)
                  }">
                  <v-row
                    v-for="(checklist, checklistIndex) in deploymentDocuments(item)"
                    :key="`checklist-${checklistIndex}-critics`"
                    style="max-height: 45px;">
                    <v-col
                      class="pl-0">
                      <v-img
                        :key="checklist.ids"
                        v-if="checklist.document && checklist.statusCode === 'risk'"
                        style="cursor: pointer;"
                        :src="svgPath"
                        max-height="17px"
                        contain
                        @click="openCriticsModal(checklist)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <template v-slot:[`item.action`]="{ item, index }">
                <v-container
                  fluid
                  :class="{
                    'py-0': true,
                    'd-flex align-end': !checkShippingStatusChecklist(item)
                  }">
                  <v-row
                    no-gutters>
                    <v-col
                      cols="12"
                      v-for="(checklist, checklistIndex) in deploymentDocuments(item)"
                      :key="`checklist-${checklistIndex}-action`"
                      :style="`min-height: ${rowHeight};`"
                      class="d-flex align-center">
                      <div
                        style="min-width: 280px;">
                        <FileDropzone
                          ref="FileDropzone"
                          v-if="!item.documentTypeField || item.documentTypeField === 'file'"
                          :isMultiple="false"
                          @files="setFile(checklist, $event, index, item.deploymentDocuments)"
                          :disabled="actionFieldValidation(checklist)"
                          height="40px"
                          actionBtnHeight="30px"
                          actionBtnWidth="30px"
                        />

                        <TextField
                          ref="TextField"
                          v-if="['smartphone', 'email', 'text'].includes(item.documentTypeField)"
                          :attrs="{
                            title: setTitle(item),
                            rules: setRuleTypes(item, checklist),
                            vMask: setDocumentTypeFieldMask(item),
                          }"
                          @updateValue="setDocumentValue(checklist, $event, index, item.deploymentDocuments)"
                          :disabled="actionFieldValidation(checklist)"
                          height="40px"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </ModalContentTable>
          </v-form>
          <div
            class="pt-2 pb-7 px-3">
            <UserMessage
              :message="`Para uma melhor organização, recomendamos
                fazer o upload de cada documento conforme a ordem do
                check-list.<br>Caso prefira, você também pode enviar
                todos os arquivos de uma vez,
                <span class='clickable' style='cursor: pointer; color: #5DAAF5; text-decoration: underline;'>clicando aqui</span>.`"
              @click="openNewDocumentModal"
            />
          </div>
        </div>

        <v-divider />
      </template>
      <template slot="action">
        <div
            class="px-3 pt-2 pb-4" style="width: 100%">
          <v-container
              fluid>
            <v-row>
              <v-btn
                  large
                  outlined
                  color="primary"
                  class="rounded-lg elevation-1"
                  @click="openNewDocumentModal">
                Inserir novo documento
              </v-btn>

              <v-spacer />

              <v-btn
                  large
                  color="secondary"
                  class="mr-6 rounded-lg black--text"
                  width="200"
                  @click="closeModal">
                Cancelar
              </v-btn>

              <v-btn
                  large
                  color="primary"
                  class="white--text rounded-lg"
                  width="200"
                  :disabled="checklistPayload.length === 0"
                  @click="updateChecklist">
                Salvar
              </v-btn>
            </v-row>
          </v-container>
        </div>
      </template>
    </Modal>

    <NewDocumentModal
      ref="NewDocumentModal"
      @updateNewDocument="updateNewDocument"
    />

    <CommentModal
      ref="CommentModal"
      title="Comentários"
      :isCombo="false"
    />

    <CriticsModal
      ref="CriticsModal"
    />

    <v-snackbar
      v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import ProgressLoader from '../../components/structure/progressLoader.vue';
import FileDropzone from '../../components/file-dropzone/fileDropzone.vue';
import AttachmentMixin from '../atendimento/functions/attachmentMixin';
import StatusBadge from '../../components/structure/statusBadge.vue';
import UserMessage from '../../components/structure/userMessage.vue';
import RequestMixin from '@/view/atendimento/functions/requestMixin';
import ContractService from '../../services/odoo/ContractService';
import DocumentsMixins from '../../shared/mixins/documentsMixins';
import DocumentService from '../../services/odoo/documentService';
import TextField from '../../components/structure/textField.vue';
import SendDocumentsTable from './sendDocumentsTable.vue';
import Modal from '../../components/structure/modal.vue';
import ModalContentTable from './modalContentTable.vue';
import NewDocumentModal from './newDocumentModal.vue';
import { mapGetters, mapMutations } from 'vuex';
import { formrules } from '../../common/formrules';
import CriticsModal from './criticsModal.vue';
import CommentModal from './commentModal.vue';
import ActionBtn from './actionBtn.vue';

export default {
  name: "EnterpriseTable",

  components: {
    SendDocumentsTable,
    ModalContentTable,
    NewDocumentModal,
    ProgressLoader,
    FileDropzone,
    CriticsModal,
    CommentModal,
    UserMessage,
    StatusBadge,
    TextField,
    ActionBtn,
    Modal,
  },

  data: () => ({
    model: [],
    title: '',
    formrules,
    documents: [],
    contracts: [],
    documentService: null,
    checklist: null,
    cnpj: null,
    contractId: null,
    contractService: null,
    financialGroupId: null,
    addNewCnpjLoading: false,
    checklistPayload: [],
    snackbar: false,
    snackbarText: null,
    limitFileSize: 12,
    rowHeight: "45px",
  }),

  mixins: [
    DocumentsMixins,
    AttachmentMixin,
    RequestMixin,
  ],

  methods: {
    ...mapMutations(["loading", "setChecklist"]),

    async openModal(item) {
      this.title = `Documentos - ${item.documentNumber}`;

      this.documents = item.checklistDocumentDTOS;
      this.checklist = item;

      this.$refs.EnterpriseModal.open();
    },

    closeModal() {
      this.title = '';
      this.checklist = null;
      this.documents = [];
      this.checklistPayload = [];
      this.$refs.EnterpriseModal.close();
    },

    openNewDocumentModal() {
      this.$refs.NewDocumentModal.open();
    },

    openCriticsModal(item) {
      this.$refs.CriticsModal.open([item.document]);
    },

    async updateChecklist() {
      if (!this.$refs.EnterpriseForm.validate()) return;

      let hasInvalidFiles = false;

      for (let checklist of this.checklistPayload) {
        if (checklist.fileContent) {
          if (this.fileSizeValidation([checklist.fileContent])) {
            hasInvalidFiles = true;
          };
          checklist.fileContent = await this.mountAttachment(checklist.fileContent);

          checklist.fileContent = checklist.fileContent.datas;
        }
      }

      if (hasInvalidFiles) return;

      this.loading(true);

      await this.documentService.UpdateChecklist(this.checklistPayload)
        .then(async () => {
          this.resetAllFields();
          await this.getChecklistDocumentsByFinancialGroupId();
          this.$refs.EnterpriseModal.close();
        })
        .finally(() => {
          this.loading(false);
        });
    },

    resetAllFields() {
      this.$refs.EnterpriseForm.reset();
      if (this.$refs.FileDropzone) {
        this.$refs.FileDropzone.forEach((ref) => {
          if (ref) ref.cleanAllFiles();
        })
      }

      if (this.$refs.TextField) {
        this.$refs.TextField.forEach((ref) => {
          if (ref) ref.reset();
        })
      }
      this.$forceUpdate();
    },

    async updateNewDocument(documentType) {
      if (!this.$refs.NewDocumentModal.validate()) return;

      let payload = [];

      let hasInvalidFiles = false;

      if (documentType.files) {
        if (Array.isArray(documentType.files)) {
          for (let file of documentType.files) {
            if (this.fileSizeValidation([file])) {
              hasInvalidFiles = true;
            };
            const attachment = await this.mountAttachment(file);

            payload.push({
              documentCnpjIds: this.checklist.cnpjIds,
              fileContent: attachment.datas,
              fileName: file.name,
              documentValue: null,
              documentTypeId: this.outhersDocumentTypeId,
            });
          }
        } else {
          documentType.files = await this.mountAttachment(documentType.files);

          documentType.files = documentType.files.datas;
        }
      }

      if (hasInvalidFiles) return;

      this.loading(true);

      await this.documentService.UpdateChecklist(payload)
        .finally(async () => {
          await this.getChecklistDocumentsByFinancialGroupId();
          this.loading(false);
          this.$refs.NewDocumentModal.close();
          this.showmsg({ text: "Documento enviado com sucesso!", type: 'error' });
        });
    },

    async openDocumentModal() {
      this.addNewCnpjLoading = true;

      await this.contractService.FindContract(this.financialGroupId)
        .then((response) => response.data)
        .then((result) => {
          result = result.map((contract) => ({
            ...contract,
            label: `${contract.carrierName} - ${contract.benefitName}`
          }));

          result = result.sort((a, b) => (a.label > b.label ? 1 : -1));

          this.contracts = result
        })
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao buscar os contratos!"});
          console.error(err);
        })
        .finally(() => this.addNewCnpjLoading = false);

      this.$refs.AddDocumentModal.open();
    },

    async closeDocumentModal() {
      this.cleanFields();
      this.$refs.AddDocumentForm.reset();
      this.$refs.AddDocumentModal.close();
    },

    async addNewCnpj() {
      this.addNewCnpjLoading = true;

      await this.contractService.AddContractualAgreement(this.cnpj, this.contractId)
        .then(() => {
          this.$emit('updateChecklist')
          this.cleanFields();
        })
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao adicionar um novo CNPJ!"});
          console.error(err);
        })
        .finally(() => {
          this.addNewCnpjLoading = false;
        });

        this.$refs.AddDocumentModal.close();
    },

    cleanFields() {
      this.cnpj = null;
      this.contractId = null;
    },

    async getDocuments(item) {
      await this.documentService.DownloadDocument(item.id)
        .then((response) => {
          const contentDispositionHeader = response.headers['content-disposition'];
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const fileName = contentDispositionHeader
            ? fileNameRegex.exec(contentDispositionHeader)
            : 'documento.pdf';

          return {
            fileName: fileName,
            file: response.data,
          }
        })
        .then(({fileName, file}) => {
          const blob = new Blob([file], { type: "application/pdf" })
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');

          link.href = url;
          link.download = fileName;
          link.click();
        })
        .catch((err) => this.showmsg({ text: "Não foi possivel baixar o documento!", type: "error" }));
    },

    setTitle(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Link";
      }
    },

    setFile(item, file, index, deploymentDocuments) {
      item.file = file;

      if (!file || (Array.isArray(file) && file.length === 0)) {
        this.removeChecklistFile(item)
      } else {
        this.updatePayload(item, index, deploymentDocuments);
      }
    },

    setDocumentValue(item, documentValue, index, deploymentDocuments) {
      item.documentValue = documentValue;

      this.updatePayload(item, index, deploymentDocuments);
    },

    removeChecklistFile(item) {
      const checklistIndex = this.checklistPayload.findIndex((checklist) => checklist.ids.some((id) => id === item.id));

      if (checklistIndex !== -1) {
        this.checklistPayload.splice(checklistIndex, 1);
        this.$forceUpdate();
      }
    },

    updatePayload(documentDTO, index, deploymentDocuments) {
      const dtoIndex = this.checklistPayload.findIndex((checklist) => checklist.ids.some((id) => id === documentDTO.id));

      const isFile = !documentDTO.documentTypeField || documentDTO.documentTypeField === 'file';

      if (dtoIndex === -1) {
        let documentIds = [];

        switch(documentDTO.statusCode) {
          case "pending":
            documentIds = deploymentDocuments
              .filter((document) => document.statusCode === 'pending')
              .map((item) => item.id);
            break;

          case "risk":
            documentIds = [documentDTO.id];
            break;
        }

        this.checklistPayload.push({
          ids: documentIds,
          fileContent: documentDTO.file ? documentDTO.file : null,
          fileName: isFile ? documentDTO.file && documentDTO.file.name ? documentDTO.file.name : null : null,
          documentValue: !isFile ? documentDTO.documentValue : null,
        })
      } else {
        if (!documentDTO.documentValue && !documentDTO.fileContent) {
          this.checklistPayload.splice(dtoIndex, 1);
          return;
        }

        if (isFile) {
          this.checklistPayload[dtoIndex].fileContent = documentDTO.file;
          this.checklistPayload[dtoIndex].documentValue = null;
        } else {
          this.checklistPayload[dtoIndex].fileContent = null;
          this.checklistPayload[dtoIndex].documentValue = documentDTO.documentValue;
        }
      }
    },

    fileSizeValidation(files) {
      let sizeValidationFailed = false;
      files.forEach((file) => {
        if (file.size > ( this.limitFileSize * 1000000 )) {
          this.snackbarText = `Arquivo ${file.name} esta ultrapassando tamanho máximo por arquivo de ${this.limitFileSize}MB`;
          this.snackbar = true;
          this.loading(false);
          sizeValidationFailed = true;
        }
      });

      return sizeValidationFailed;
    },

    setRuleTypes(item, checklist) {
      if (!checklist.documentValue) return [true];

      switch(item.documentTypeField) {
        case 'email':
          return  [this.formrules.email];

        case 'smartphone':
          return  [this.formrules.cellphone];

        default:
          return [true];
      }
    },

    setDocumentTypeFieldMask(item) {
      switch(item.documentTypeField) {
        case 'smartphone':
          return  "(##) #####-####";
        default:
          return false;
      }
    },

    checkShippingStatusChecklist(item) {
      const sameShippingStatus = item.deploymentDocuments.every(
        (checklist) => ["pending", "analysis"].includes(checklist.statusCode));

      return sameShippingStatus;
    },

    deploymentDocuments(item) {
      let documents = [];

      if (this.checkShippingStatusChecklist(item)) {
        documents = [item.deploymentDocuments[0]];
      } else {
        documents = item.deploymentDocuments;
      }

      return documents;
    },
  },

  computed: {
    ...mapGetters(["getChecklist"]),

    svgPath() {
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/exclamation.svg";
    },

    enterpriseChecklist() {
      if (!this.getChecklist || this.getChecklist && this.getChecklist.length === 0) return [];

      const customer = this.getChecklist.find(
        (checklist) => checklist.checklistLevelCode === 'CUSTOMER');

      return customer
        && customer.levelDTOS
        && customer.levelDTOS.length > 0
          ? customer.levelDTOS
          : [];
    },

    config() {
      return {
        headers: [
          {
            text: 'CNPJ',
            value: 'documentNumber',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Nº de anexos',
            value: 'checklistDocumentDTOS',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: '% de Preenchimento',
            value: 'fill',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Status',
            value: 'status',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'end',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
        ]
      }
    },

    modalConfig() {
      return {
        headers: [
          {
            text: 'Nome',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 4,
              xl: 4,
            }
          },
          {
            text: 'Anexos',
            value: 'attachment',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Comentários',
            value: 'comments',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 1,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'end',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 3,
              xl: 2,
            }
          },
        ]
      }
    },
  },

  created() {
    this.documentService = new DocumentService();
    this.contractService = new ContractService();
  },

  async mounted() {
    this.financialGroupId = this.$util.getSession()
      && this.$util.getSession().financialGroup
        ? this.$util.getSession().financialGroup.id
        : null;

    await this.contractService.FindContract(this.financialGroupId)
        .then((response) => response.data)
        .then((result) => this.contracts = result)
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao buscar os contratos!"});
          console.error(err);
        });

    await this.getChecklistTypeOuthers();
  },
}
</script>