<template>
  <v-container
    fluid
    class="pa-0">
    <div
      class="file-dropzone d-flex align-center justify-center"
      :style="`border: 2px dashed ${disabled ? 'gray' : color}; height: ${height};`"
      @dragover.prevent
      @drop="handleDrop"
      @dragenter="handleDragEnter"
      @dragleave="handleDragLeave"
      v-if="showOptions">
      <v-container
        style="pointer-events: none;"
        class="d-flex justify-center">
        <v-row
          no-gutters
          :class="`d-flex align-center justify-${justify}`"
          :style="`
            pointer-events: none;
            max-width: ${contentWidth};`">
          <div
            class="d-flex justify-center"
            style="width: 80%;">
            <label
              :style="`color: ${disabled ? 'gray' : color}`">
              {{ message }}
            </label>
          </div>
          <div
            style="pointer-events: auto;">
            <ActionBtn
              :height="actionBtnHeight"
              :width="actionBtnWidth"
              :icon="'mdi-upload'"
              :color="disabled ? '#808080' : '#5555A5'"
              :disabled="disabled"
              @click="openFileFolder"
            />
          </div>
        </v-row>
      </v-container>
    </div>
    <v-file-input
      v-show="(!Array.isArray(files) && !!files) || (Array.isArray(files) && files.length > 0)"
      ref="FileInput"
      name="FileDropzone"
      accept=".xls,.xlsx,.csv,.txt,.pdf,.zip"
      :multiple="isMultiple"
      chips
      hide-details
      v-model="files"
      prepend-icon=""
      append-icon=""
      :clearable="false"
      truncate-length="100"
      :disabled="disabled"
      style="
        overflow: auto;
        width: 100%;"
      class="py-0">
      <template v-slot:selection="{index, text}">
        <div
          class="d-block my-1"
          style="width: 100%; pointer-events: auto;">
          <v-sheet
            close
            large
            height="40"
            max-width="98%"
            class="white d-flex align-center px-1"
            :style="`box-shadow: 0px 3px 6px #270B5226; border-radius: 5px; max-width: ${maxWidthFile}`">
            <div
              class="d-flex flex-row align-center justify-center px-1"
              style="height: 30px; width: 100%;">
              <v-icon
                class="mr-1"
                color="secondary"
                size="17">
                fas fa-paperclip
              </v-icon>
              <div
                class="d-flex flex-row align-center justify-space-between px-1 white--text rounded-lg"
                style="font-size: 12px; width: 100%; height: 100%; background-color: #5DAAF5;">
                <div
                  class="text-truncate mr-1">
                  {{ text }}
                </div>

                <v-btn
                  fab
                  class="white"
                  color="primary"
                  height="20"
                  width="20"
                  style="pointer-events: auto;"
                  @click.stop="removeFile(index)">
                  <v-icon
                    size="10">
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-sheet>
        </div>
      </template>
    </v-file-input>
  </v-container>
</template>
<script>
import ActionBtn from '../../view/sendDocuments/actionBtn.vue';

export default {
  name: "FileDropzone",

  components: {
    ActionBtn,
  },

  data: () => ({
    defaultClassColumn: 'py-0',
    files: [],
    message: "Arraste ou clique",
    isHandleDragEnter: false,
  }),

  props: {
    color: {
      type: String,
      default: "#5555A5",
    },
    height: {
      type: String,
      default: "50px"
    },
    justify: {
      type: String,
      default: "space-between"
    },
    contentWidth: {
      type: String,
      default: "220px"
    },
    maxWidthFile: {
      type: String,
      default: "280px"
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    filesImported: {
      required: false,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    disabled:  {
      type: Boolean,
      default: false
    },
    actionBtnHeight: {
      type: String,
      default: "40px"
    },
    actionBtnWidth: {
      type: String,
      default: "40px"
    }
  },

  watch: {
    files: {
      deep: true,
      handler(newValue) {
        this.$emit('files', newValue);
      },
    },

    filesImported: {
      deep: true,
      handler(newValue) {
        this.files = newValue;
      },
    }
  },

  methods: {
    openFileFolder() {
      if (this.disabled) return
      this.$refs.FileInput.$refs.input.click();
    },
    removeFile(index) {
      if (this.disabled) return
      if (this.isMultiple) {
        this.files.splice(index, 1);
      } else {
        if (this.isMultiple) {
          this.files = [];
        } else {
          this.files = null;
        }
      }
    },
    cleanAllFiles() {
      if (this.isMultiple) {
        this.files = [];
      } else {
        this.files = null;
      }
    },
    async handleDrop(event) {
      if (this.disabled) return
      if (event && event.dataTransfer && event.dataTransfer.files) {
        const files = Array.from(event.dataTransfer.files);
        this.files = this.isMultiple ? files : files[0];
        event.preventDefault();
        this.handleDragLeave();
      }
    },
    handleDragEnter(event) {
      if (this.disabled) return
      event.preventDefault();
      this.message = 'Solte os arquivos aqui';
      this.isHandleDragEnter = true;
    },
    handleDragLeave() {
      if (this.disabled) return
      this.message = "Arraste ou clique";
      this.isHandleDragEnter = false;
    },
  },
}
</script>
<style scoped>
.file-dropzone {
  width: 100%;
  border-radius: 10px;
  position: relative;
  background-color: #F6F2FC;
}
</style>
<style>
#FileInputDropzone > div > div > div {
  border-color: transparent !important;
}
#FileInputDropzone > div > div > div::before {
  border-color: transparent !important;
}
#FileInputDropzone > div > div > div::after {
  border-color: transparent !important;
}
</style>