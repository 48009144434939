<template>
  <v-sheet
    class="d-flex align-center justify-center rounded-pill"
    :height="height"
    width="100%"
    :style="{
      backgroundColor: color,
    }">
    <span
      :style="{
        fontSize: '14px',
        color: setStatusColor({color: color})
      }">
      {{ label }}
    </span>
  </v-sheet>
</template>

<script>
import DocumentsMixins from '../../shared/mixins/documentsMixins';
export default {
  name: "StatusBadge",

  props: {
    label: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      required: true,
    },

    height: {
      type: Number,
      default: 30,
      requred: false,
    },

    colorBrightnessPercentage: {
      type: Number,
      default: 40,
      requred: false,
    }
  },

  mixins: [
    DocumentsMixins,
  ],
}
</script>