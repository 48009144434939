import { extension, lookup } from "mime-types";
import { saveAs } from "file-saver";

export default {
  methods: {
    downloadFile(attachment) {
      this.loading = true;
      this._contentVersionService
        .getDownloadFile(attachment.id)
        .then(attachmentData => {
          var file_name = attachment.name;
          var _correct_extension = attachment && attachment.mimetype ? extension(attachment.mimetype) : ''
          var extension_dot = "." + _correct_extension
          if(!file_name.endsWith(extension_dot)) {
            file_name = file_name + extension_dot
          }
          saveAs(
            new Blob([attachmentData.data], {type: lookup(attachment.mimeType)}),
            file_name
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mountAttachment(attachment) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => fileReader.abort();

        fileReader.onload = () => resolve({
          name: attachment.name,
          datas: fileReader.result.split(",")[1],
          description: "CLIENT",
        });

        fileReader.readAsDataURL(attachment);
      });
    },

    openFileSelector(accept) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = accept;
      fileInput.multiple = true;

      fileInput.addEventListener('change', (event) => {
          const files = event.target.files;
          this.currentCase.files = [];

          Array.from(files).forEach((file) => {
            if (file) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (event) => {
                this.currentCase.files.push({
                  name: file.name,
                  body: event.target.result
                });
              }
            }
          })
      });

      document.body.appendChild(fileInput);

      fileInput.click();

      fileInput.remove();
    },

    openCamera() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) return;

      const attrs = {
        video: true,
        audio: false
      };

      navigator.mediaDevices.getUserMedia(attrs)
        .then((stream) => {
          const videoElement = document.createElement('video');
          videoElement.srcObject = stream;
          videoElement.autoplay = true;
          videoElement.style.width = '100%';
          videoElement.style.height = 'auto';

          document.body.appendChild(videoElement);

          videoElement.dataset.streamId = stream.id;
        });
    },

    stopCamera() {
      const videoElement = document.querySelector('video');
      if (videoElement) {
        const stream = videoElement.srcObject;

        if (stream && stream.getTracks) {
          stream.getTracks().forEach((track) => track.stop());
        }
        videoElement.remove();
      }
    }
  },
}