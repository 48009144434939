<template>
  <v-dialog
    v-model="show"
    :width="dialogWidth">
    <v-card
      class="rounded-lg">
      <v-toolbar
        width="100%"
        :color="color"
        height="79"
        style="position: relative;">
        <v-row
          class="d-flex justify-center">
          <v-btn
            v-if="useBackBtn"
            icon
            class="white elevation-4 d-flex justify-center align-center"
            :color="color"
            heiht="43"
            max-height="43"
            width="43"
            style="
              position: absolute;
              left: 30px;
              top: 20%;"
            @click="close">
            <v-icon
              size="30">
              mdi-arrow-left
            </v-icon>
          </v-btn>

          <span
            class="text-h6 white--text font-weight-bold">
            {{ title }}
          </span>

          <v-btn
            v-if="useCloseBtn"
            icon
            class="white elevation-4 d-flex justify-center align-center"
            :color="color"
            heiht="43"
            max-height="43"
            width="43"
            style="
              position: absolute;
              right: 30px;
              top: 20%;"
            @click="close">
            <v-icon
              size="30">
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </v-toolbar>

      <v-card-text
        class="px-0 pb-0">
        <slot name="body"></slot>
      </v-card-text>

      <v-card-actions
          class="px-3 pb-0">
        <slot name="action"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",

  data: () => ({
    show: false,
  }),

  props: {
    title: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: 'primary',
      required: false,
    },

    useBackBtn: {
      type: Boolean,
      default: false,
    },

    useCloseBtn: {
      type: Boolean,
      default: true,
    },

    dialogWidth: {
      type: String,
      default: "100%"
    }
  },

  methods: {
    open() {
      this.show = true;
    },

    close() {
      this.$emit('close');

      this.show = false;
    }
  }
}
</script>